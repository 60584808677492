import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';
import { DataService } from 'src/app/services/data.service';
declare var $:any;
@Component({
  selector: 'app-welcome-chat',
  templateUrl: './welcome-chat.component.html',
  styleUrls: ['./welcome-chat.component.scss']
})
export class WelcomeChatComponent implements OnInit, AfterViewInit {
  textMessage = new FormControl('');
  msg;
  welcomeChatList = [];
  interval;
  check:any;
  exhibiton:any=[];
  documents:any=[];
  constructor(private _ds:DataService, public chat:ChatService) { }

  ngOnInit(): void {
    this.getHelpdeskList();
  }

  ngAfterViewInit(){    
    this.chat.socketConnection();  
    this.chat.getSocketMessages().subscribe((data:any)=>{
      console.log(data)
      this.check = data.split('_');
      // alert(this.check)
      if (this.check[0] == 'question' && this.check[1] == 'reply') {
        this.getHelpdeskList();
      }
      if (this.check[0] == 'start' && this.check[1] == 'poll' && this.check[2]=='205214') {
        $('#poll_modal').modal('show')
      }
    });
  }

  getHelpdeskList(){ 
    let data = JSON.parse(localStorage.getItem('virtual'));
    this._ds.getHelpdeskList(data.id).subscribe((res:any)=>{
      this.welcomeChatList = res.result;
    });
  }
  postMyQuestion(value){
    if(value != '' && value != null){
      let data = JSON.parse(localStorage.getItem('virtual'));
        this._ds.postHelpDeskQuestion(data.id,value).subscribe((res=>{
          if(res.code == 1){
            this.textMessage.reset();
            let arr={
              "question":value,
              "answer":""
            };
            this.welcomeChatList.push(arr);
          }
        }));
    }
  }
  closeModal(){
    $('#welcome_chat_modal').modal('hide');
  }
}
