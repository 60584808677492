import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Eventid } from '../shared/eventid';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.baseUrl;
  event_id = Eventid.event_id;
  allItems = new Subject<any>();

  constructor(private http: HttpClient) { 
    this.settingJson();
  }
  settingItems: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  settingItems$ = this.settingItems.asObservable();
    // I need to maintain cart, so add items in cart

  addCartData(data) {
    const currentValue = this.settingItems.value; // get current items in cart
    const updatedValue = [...currentValue, data]; // push new item in cart
    if(updatedValue.length) {
      this.settingItems.next(updatedValue); // notify to all subscribers
    }
  }
    
 
  settingJson(){
    return this.http.get('assets/filesetting/setting.section.json').subscribe(res=>{
      this.allItems.next(res);
      this.addCartData(res);
    });
  } 
 /*  settingJson(){
    return this.http.get(`https://d3ep09c8x21fmh.cloudfront.net/virtual_event/event_${this.event_id}/config.json`).subscribe(res=>{
      this.allItems.next(res);
      this.addCartData(res);
    });
  } */
  public getMessages(){
    return this.allItems.asObservable();
  }
  signupMethod(data:any){
    return this.http.post(`${this.baseUrl}/user/register/event_id/${this.event_id}`, data);
  }

  // loginMethod(loginObj:any){
  //   return this.http.get(`${this.baseUrl}/auth/login/event_id/${loginObj.event_id}/email/${loginObj.email}/role_id/${loginObj.role_id}`);
  // }
  loginMethod(data:any){
    return this.http.post(`${this.baseUrl}/auth/auto/login/event_id/${this.event_id}`,data);
  }
}