<div id="sidebar">
    <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" (click)="closeSidebar()">&times;</a>
        <ul id="sidenavList">
            <ng-container *ngFor="let menu of menuItems">
                <ng-container *ngIf="menu.is_enabled">
                    <li *ngIf="!menu.is_modal">
                        <a routerLink="/{{menu.path}}" routerLinkActive="activeLink" (click)="stepUpAnalytics1(menu.path)">
                            <img src="{{menu.icon}}" alt="">
                            {{menu.name | titlecase}}
                        </a>
                      
                    </li>
                    <li *ngIf="menu.is_modal">
                        <a (click)="openModal(menu.data_target)">
                            <img src="{{menu.icon}}" alt="">
                            {{menu.name | titlecase}}
                        </a>
                        
                    </li>
                   
                </ng-container>  
                          
            </ng-container>           
        </ul>
    </div>
    <div id="hamburger" (click)="openSidebar()">&#9776;</div>
</div>
<div *ngIf="!url.url.includes('auditorium/full') && !url.url.includes('photobooth')" class="footer-nav">
    <ul class="footer-nav1">
        <ng-container *ngFor="let menu of menuItems">
            <ng-container *ngIf="menu.is_enabled">
                <li *ngIf="!menu.is_modal">
                    <a [routerLink]="'/'+menu.path" routerLinkActive="activeLink" (click)="stepUpAnalytics1(menu.path)">
                        <img src="{{menu.icon}}" alt="" style="background: white;
                        padding: 10px;
                        border-radius: 20px;">
                        <div class="pl-2 pr-2" style="background: white; border-radius: 20px;">{{menu.name | uppercase}}</div>
                    </a>
                </li>
                <li *ngIf="menu.is_modal">
                    <a (click)="openModal(menu.data_target)">
                        <img src="{{menu.icon}}" alt="" style="background: white;
                        padding: 10px;
                        border-radius: 20px;">
                        <div class="pl-2 pr-2" style="background: white; border-radius: 20px;">{{menu.name | uppercase}}</div>
                    </a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>