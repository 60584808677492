<span id="signout">
    <a id="funBtn" class="submitBtn" (click)="submitPageAnalytics('click_Logout');exit();"  data-bs-toggle="tooltip"  role="button">
        Logout 
    </a>
    </span>

    
    


<div class="livemsg" id="countdown" style="display: none;">

</div>
<span id="goBack" [ngStyle]="{'display': (router.url.includes('auditorium') || router.url.includes('exhibition') || router.url.includes('photobooth'))?'block':'none'}">
    <a id="funBtn" class="submitBtn" (click)="gotoBack()"  data-bs-toggle="tooltip" role="button">
        Back 
    </a>
</span>
<router-outlet></router-outlet>
<app-menu (outputMenu)="readOutputMenu($event)"></app-menu>
<app-briefcase-modal></app-briefcase-modal>
<app-leaderboard-modal></app-leaderboard-modal>
<app-welcome-chat></app-welcome-chat>