<!-- <button class="btn btn success" (click)="getCall()">call</button> -->
<div *ngIf="isLoaded">
  <div class="loading">
      <!-- <p>Loading...</p> -->
      <div class="loader"></div>

    </div>
</div>
<!-- <router-outlet></router-outlet> -->
<div [ngStyle]="{'display':landscape?'none':'block'}">
<div id="potrait">
    <img src="assets/event/rotate.gif" alt="">
    <p>Switch to Landscape view</p>
</div>
</div>
<div [ngStyle]="{'display':landscape?'block':'none'}">
<router-outlet></router-outlet>
</div>