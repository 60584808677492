import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './@main/layout.component';
import { MaincallComponent } from './maincall/maincall.component';

const routes: Routes = [
  { path:'', redirectTo:'login', pathMatch:'prefix'},
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: 'maincall', component:MaincallComponent},
  {
    path: '',
    component: LayoutComponent,
    children: [
        { path: '', loadChildren: ()=>import('./@main/layout.module').then(m=>m.LayoutModule)}
      ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
