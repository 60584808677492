import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
declare var $:any;
@Component({
  selector: 'app-leaderboard-modal',
  templateUrl: './leaderboard-modal.component.html',
  styleUrls: ['./leaderboard-modal.component.scss']
})
export class LeaderboardModalComponent implements OnInit {
  leaderboardList = [];
  leaderboardDatalist = [];
  userDataList = [];
  criteria_data =[];
  myPoints: number = 0;
  navpills=[
    { pill: 'leaderboard', class:'active', pillClass: 'show active', is_selected: true},
    { pill: 'points', class:'', pillClass: '', is_selected: false},
    { pill: 'criteria', class:'', pillClass: '', is_selected: false},
    { pill: 'Terms & conditions', class:'', pillClass: '', is_selected: false},
  ];
  constructor(private _ds:DataService, private _auth:AuthService) { }

  ngOnInit(): void {
    this._auth.settingItems$.subscribe((items:any) => {
      for (const key in items[0]) {
        if (key === 'leaderboardCriteria')
          this.criteria_data = items[0][key];        
      }      
      // this.criteria_data = items.length?items[0]["leaderboardCriteria"]:items[0]["leaderboardCriteria"];       
    });
    // this.getLeaderboardList();
  }
  onSearchItem(item){
    this.leaderboardList = this.leaderboardDatalist.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(item.toLowerCase()) !== -1);
    // let abc = this.leaderboardList.filter(x=>x.attendee.toLowerCase().includes(item))
    // return abc;
  }
  getLeaderboardList(){
    this._ds.getLeaderBoradDataList().pipe(map((data:any)=>{
      this.leaderboardList = data.result['leaderboard'];
      this.leaderboardDatalist = this.leaderboardList;
      this.userDataList = data.result['mydata'];
      data.result['mydata'].forEach(element => {
        this.myPoints += parseInt(element.points)
      });
    })).subscribe();
    let item:any = document.getElementById('search_item');
    item.value='';
  }
  selectPill(pill){
    const index = this.navpills.indexOf(pill);
    console.log(index)
    this.navpills[index]['class'] = 'active';
    this.navpills[index]['pillClass'] = 'show active';
    this.navpills[index]['is_selected'] = true;
    for (let i = 0; i < this.navpills.length; i++) {
      if(i != index){
        this.navpills[i]['class'] = '';
        this.navpills[i]['pillClass'] = '';
        this.navpills[i]['is_selected'] = false;
      }      
    }
  }
  closeModal(){
    $('#leaderboard_modal').modal('hide');
  }
}
