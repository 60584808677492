import { ChatService } from 'src/app/services/chat.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { DataService } from '../services/data.service';
declare var $: any;
@Component({
  selector: 'app-maincall',
  templateUrl: './maincall.component.html',
  styleUrls: ['./maincall.component.scss']
})
export class MaincallComponent implements OnInit {

  frameUrl;
  key;
  name: any;
  getFrame;
  flag = '1';
  constructor(private _fd: DataService, private chat: ChatService, private router: Router, private locationStrategy: LocationStrategy) { }

  ngOnInit(): void {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
    this.geturl();
    this.start_Call();
    this.chat.getconnect('6059ecd30air3');
    this.chat.getMessages().subscribe((data => {
      console.log('data', data);
      let checkData = data.split('*');
      let mymail = localStorage.getItem('myemail');
      if (checkData[0] == mymail && checkData[1] == 'exit') {
        this.flag = '0';
        this.start_Call();
        this.router.navigate(['/lobby']);


      }
    }));
  }
  geturl() {
    this.key = localStorage.getItem('user_key');
    this.name = JSON.parse(localStorage.getItem('getdata')).name;
    this.frameUrl = 'https://www.vmixcall.com/call.aspx?Key=' + this.key + '&Name=' + this.name;
    console.log(this.frameUrl);
    this.getFrame = '<iframe style=" width:100vw;" width="100%" height="600" allow="camera *;microphone *" src="' + this.frameUrl + '" frameborder="0" allowfullscreen=""></iframe>';
    $('#frame').html(this.getFrame);
  }
  start_Call() {
    let signify_userid = JSON.parse(localStorage.getItem('getdata')).id;
    const formsData = new FormData();
    formsData.append('user_id', signify_userid);
    formsData.append('flag', this.flag);
    console.log(formsData);
    this._fd.signify_update(formsData).subscribe(res => {
      console.log(res);
    })
  }

}
