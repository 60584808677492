import { Location } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ChatService } from "../services/chat.service";
import { DataService, localService } from "../services/data.service";
import Swal from "sweetalert2";
import { AuthService } from "../services/auth.service";
import { BriefcaseModalComponent } from "./briefcase-modal/briefcase-modal.component";
import {WelcomeChatComponent} from './shared/welcome-chat/welcome-chat.component'
declare var $:any;

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild(BriefcaseModalComponent)
  private briefcase: BriefcaseModalComponent;

  constructor(
    private _ds: DataService,
    private chat: ChatService,
    private _ls: localService,
    private _auth: AuthService,
    public router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.chat.getconnect("6059ecd30air3");
    this.chat.getMessages().subscribe((data) => {
      console.log("data", data);
      // alert(data)
      let checkData = data.split("*");
      let mymail = localStorage.getItem("myemail");
      // alert(checkData)
      if (checkData[0] == mymail && checkData[1] != "exit") {
        localStorage.setItem("user_key", checkData[1]);
        var timeleft = 10;
        var downloadTimer = setInterval(() => {
          if (timeleft <= 0) {
            clearInterval(downloadTimer);
            document.getElementById("countdown").innerHTML = "";
            document.getElementById("countdown").style.display = "none";
            this.routing();
          } else {
            document.getElementById("countdown").style.display = "block";
            document.getElementById("countdown").innerHTML =
              "You are going live in " + timeleft + " seconds.";
          }
          console.log(timeleft);
          timeleft -= 1;
        }, 1000);
      }
    });
    // var arrEl = ['book', 'kick', 'rock', 'book', 'lock']
    // var books = [
    //   { title: "C++", author: "Bjarne" },
    //   { title: "Java", author: "James" },
    //   { title: "Python", author: "Guido" },
    //   { title: "Java", author: "James" },
    //   { title: "angular", author: "James" },
    //   { title: "Java", author: "kkk" },
    // ];
    // let newArray:any = [];
    // let uniqueObj:any = {};
    // books.filter((item, index)=>{
    //   uniqueObj[item.title]=index;
    //   console.log('filter',  uniqueObj);

    // });
    // for (let i in uniqueObj) {
    //   newArray.push(books[uniqueObj[i]])
    // }
    // for (const iterator of books) {

    // }
    // console.log('arr', newArray);

    // arrEl = arrEl.filter( ( item, index, inputArray )=> {
    //   return inputArray.indexOf(item) == index;
    // });
    // console.log('arr', arrEl);
  }

  routing() {
    // alert('hel');
    this.router.navigate(["/maincall"]);
  }

  gotoBack() {
    this.location.back();
  }
  ngAfterViewInit() {
    this.chat.socketConnection();
    this.chat.getSocketMessages().subscribe((data: any) => {
      console.log(data);
      const splitData = data.split("_");
      if (splitData[0] == "start" && splitData[1] == "audio") {
        // alert('hello')
        this.router.navigateByUrl("/auditorium/full/205214");
      }

      if (data.includes("start_live")) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          title: splitData[2],
        });
      }
      setTimeout(() => {
        this.getId(splitData[2]);
      }, 3000);
      /*  if(data.includes('custom_live')){
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Auditorium will begin soon'
        });
      } */
      setTimeout(() => {
        this.getId(splitData[2]);
      }, 3000);
    });
  }
  submitPageAnalytics(item) {
    this._ls.stepUpAnalytics(item);
  }
  exit() {
    let user_id = JSON.parse(localStorage.getItem("virtual")).id;
    // let signify_userid = JSON.parse(localStorage.getItem("getdata")).id;
    // const formsData = new FormData();
    // formsData.append("user_id", signify_userid);
    // formsData.append("flag", "0");
    // const newdata = new FormData();
    // newdata.append("user_id", signify_userid);
    // newdata.append("status", "0");
    // this._ds.handraise(formsData).subscribe((res) => {
    //   console.log(res);
    // });
    // this._ds.signify_update(formsData).subscribe((res) => {});
    // this._ds.handlogout(newdata).subscribe((res) => {});
    this._ds.logout(user_id).subscribe((res) => {
      this.router.navigate(["/login"]);
      localStorage.clear();
    });

    // localStorage.removeItem('virtual');
    // this.router.navigate(['/login']);
  }

  getId(id) {
    this._auth.settingItems$.subscribe((items) => {
      if (items.length) {
        let path = items[0]["auditorium"].filter((x) => x.id == id)[0]["path"];
        this.router.navigate(["/auditorium/" + path + "/" + id]);
      }
    });
  }
  readOutputMenu(item) {
    console.log(item);
    if (item === "briefcase") {
      this.briefcase.getBriefcaseList();
    } 
    if(item === 'helpdesk_chat'){
      $('#welcome_chat_modal').modal('show');       
    }
  }
}
